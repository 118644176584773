<template>
  <NavMenu>
    <div class="patient page">
      <div class="right-warp">
        <div class="right-content">
          <h2 class="content-title">
            <div class="title">
              <img src="../../assets/img/icon_1.png" alt="ico-title" />
              <span>{{$t('patient.title')}}</span>
            </div>
            <div class="mark">
              <span class="sig">*</span>
              <span>{{$t('patient.mark')}}</span>
            </div>
          </h2>
          <div class="control">
            <div class="control-left">
              <div class="input-search">
                <input type="text" v-model="params.keyword" @keyup.enter="goSearch" :placeholder="$t('patient.placeholder')" />
              </div>
              <div class="btn-search" @click="goSearch" >{{$t('common.search')}}</div>
              <div class="btn-reset" @click="reset">{{$t('common.reset')}}</div>
            </div>
            <div class="control-right">
              <div class="btn-add" @click="create">{{$t('patient.create')}}</div>
            </div>
          </div>
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{
              color: '#252B3B',
              'font-weight': 'bold',
              background: '#F8F9FA',
            }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="patient_number" :label="$t('patient.patient')" width="140">
            </el-table-column>
            <el-table-column prop="patient_name" :label="$t('patient.name')" width="140">
            </el-table-column>
            <el-table-column prop="sex_text" :label="$t('patient.sex')" width="140">
            </el-table-column>
            <el-table-column
              width="160"
              prop="birthday_text"
              :label="$t('patient.born')"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="add_time_text"
              :label="$t('patient.createtime')"
              width="180"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column :label="$t('patient.operate')">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="handleEdit(scope.$index, scope.row)"
                >
                  {{$t('patient.edit')}}
                </el-button>
                <el-button size="mini" @click="goCase(scope.row)">{{$t('patient.related')}}</el-button>
                <el-button
                  size="mini"
                  @click="handleDelete(scope.$index, scope.row)"
                >
                  {{$t('patient.delete')}}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="list-foot">
            <div class="">
              <!-- <el-button class="btn" @click="toggleSelection()"
                >全部选择</el-button
              > -->
              <el-button class="btn" @click="toggleSelection()"
                >{{$t('patient.reverse')}}</el-button
              >
              <el-button class="btn" 
                @click="delAll">{{$t('patient.delBatches')}}</el-button
              >
            </div>
            <el-pagination @current-change="nowpage" background layout="prev, pager, next" :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </NavMenu>
  
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
	  total:0,
      multipleSelection: [],
	  params: {
	  	page: 1,
	  	limit: 10,
	  	keyword: "",
	  },
    };
  },
mounted(){
	this.getPatientList();
},
  methods: {
	  goSearch(){
			this.params.page = 1;this.getPatientList();
	  },
	  // 当前页
	  nowpage(e) {
	  	this.params.page = e;
	  	this.getPatientList();
	  },
	  reset(){
	  			this.params.page = 1;
	  			this.params.keyword = '';
				this.getPatientList();
	  },
	  goCase(row){
		  this.$router.push({path:"/case/list",query:{patient_id:row.id}})
	  },
	  getPatientList(){
			this.$api.patientList(this.params).then(res=>{
				this.tableData = res.data.data;
				this.total = res.data.count;
			})
			.catch(err => {
				// this.$message.error(this.$t('common.errMsg'));
			})
	  },
    //创建
    create(){
      this.$router.push('/patient/create');
    },
    toggleSelection() {
      this.tableData.forEach((row) => {
        this.$refs.multipleTable.toggleRowSelection(row);
      });
      // this.$refs.multipleTable.clearSelection();
    },
    //选中
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    //编辑
    handleEdit(index, row) {
      // this.$router.push('/patient/edit?id='+ row.id);
      this.$router.push('/patient/create?id='+ row.id);
    },
    //删除
    handleDelete(index, row) {
      this.$confirm(this.$t('patient.delWarn'), this.$t('common.warn'), {
      	confirmButtonText: this.$t('common.confirm'),
      	cancelButtonText: this.$t('common.cancel'),
      	type: 'warning'
      }).then(() => {
      	this.$api.delPatient(row.id).then(res => {
      			this.$message.success(res.msg);
      			if (res.code == 1) {
      				this.tableData.splice(index, 1);
      			}
      		})
      		.catch(err => {
      			// this.$message.error(this.$t('common.errMsg'));
      		})
      }).catch(() => {
      
      });
    },
	delAll(){
		if(this.multipleSelection.length > 0){
			var c = [];
			this.multipleSelection.map(res=>{
				c.push(res.id);
			})
			console.log(c)
			this.$confirm(this.$t('patient.delWarn'), this.$t('common.warn'), {
				confirmButtonText: this.$t('common.confirm'),
      	cancelButtonText: this.$t('common.cancel'),
				type: 'warning'
			}).then(() => {
				this.$api.delPatient(c.join(',')).then(res => {
						this.$message.success(res.msg);
						if (res.code == 1) {
							this.params.page = 1;
							this.getPatientList();
						}
					})
					.catch(err => {
						// this.$message.error(this.$t('common.errMsg'));
					})
			}).catch(() => {
			
			});
		}
	},
  },
};
</script>
<style scoped>
.right-content {
  min-height: 757px;
  position: relative;
}
.content-title .mark {
  font-family: FZZhunYuan-M02S;
  font-weight: 400;
  line-height: 159px;
  font-size: 13px;
}
.sig {
  color: #ff0000;
}
.right-content .form {
  display: flex;
  justify-content: space-between;
  padding: 30px;
}
.right-content .form-left,
.right-content .form-right {
  width: 45%;
}
.right-content .form .foot {
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  text-align: center;
}
.right-content .form .foot input {
  margin: 0 12px;
}
.btn {
  background: #fff;
}
.control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 27px;
  margin-top: 20px;
}
.control .control-left {
  display: flex;
  align-items: center;
}
.input-search {
  position: relative;
}
.input-search input {
  width: 181px;
  height: 35px;
  background: #f6f6f6;
  border: none;
  border-radius: 18px;
  padding-left: 60px;
  font-size: 15px;
  font-weight: 400;
}
.input-search:before {
  content: "";
  background: url(../../assets/img/search_1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 50%;
  left: 28px;
  transform: translate(0%, -50%);
}
.input-search:focus {
  outline: 0;
  border: 0;
}
.btn-search {
  width: 85px;
  height: 35px;
  line-height: 35px;
  background: #364284;
  border-radius: 18px;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  position: relative;
  text-align: center;
  padding-left: 40px;
  margin: 0 17px;
}
.btn-add {
  width: 105px;
  height: 35px;
  line-height: 35px;
  background: #364284;
  border-radius: 18px;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  position: relative;
  text-align: center;
  padding-left: 20px;
  margin: 0 17px;
}
.btn-search:hover,
.btn-reset:hover,
.btn-add:hover {
  cursor: pointer;
}
.btn-search:before {
  content: "";
  background: url(../../assets/img/search_1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 50%;
  left: 28px;
  transform: translate(0%, -50%);
}
.btn-add:before {
  content: "";
  background: url(../../assets/img/create.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 16px;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(0%, -55%);
}
.btn-reset {
  width: 125px;
  height: 35px;
  background: #7ecdf6;
  border-radius: 18px;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  line-height: 35px;
  text-align: center;
}
.list-foot {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  /* position: absolute;
  bottom: 30px;
  left: 20px;
  right: 20px; */
}
</style>
